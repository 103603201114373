import React from 'react';
// import { Helmet } from 'react-helmet';
// import './style.scss';
import axios from 'axios';
//
import * as config from 'config';
import components from 'components/Index';

import aprogen from 'assets/img/aprogen.png';
import crystalcenomics from 'assets/img/crystalcenomics.png';
import dass from 'assets/img/dass.png';
import hdxwill from 'assets/img/hdxwill.png';
import ktoto from 'assets/img/ktoto.png';
import sualab from 'assets/img/sualab.png';
import wanted from 'assets/img/wanted.png';
import enpgames from 'assets/img/enpgames.png';
import aprogenmedi from 'assets/img/aprogenmedi.png';

import { injectIntl } from 'react-intl';

class Portfolio extends React.Component {
  state = {
    data: null
  };

  async initialize() {
    try {
      const response = await axios.get(
        `${config.API_BOARD}?type=PORTFOLIO&_page=1&_limit=30&_order=board_id&_sort=ASC`
      );
      console.log(response.data);
      this.setState({
        data: response.data
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.initialize();
  }

  render() {
    const { intl } = this.props;
    const { data } = this.state;
    return (
      <div id="portfolio" className="portfolio">
        <components.Masthead addClass={'portfolio'} title={'Portfolio'} />
        <div className="content">
          <div className="portfolio__desc">{intl.formatMessage({ id: 'portfolio__desc' })}</div>
          <div className="portfolio_list">
            {data ? (
              data.map(r => {
                return (
                  <div key={r.board_id} className="portfolio_item">
                    <a href={r.title} target="_blank" rel="noopener noreferrer">
                      <img src={r.image} alt="" />
                    </a>
                  </div>
                );
              })
            ) : (
              <div>
                <div className="portfolio_item">
                  <a href="http://www.aprogen.com" target="_blank" rel="noopener noreferrer">
                    <img src={aprogen} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a
                    href="http://www.crystalgenomics.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={crystalcenomics} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a href="http://www.dasstech.com/kor" target="_blank" rel="noopener noreferrer">
                    <img src={dass} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a href="http://willmed.co.kr" target="_blank" rel="noopener noreferrer">
                    <img src={hdxwill} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a href="http://ktoto.co.kr" target="_blank" rel="noopener noreferrer">
                    <img src={ktoto} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a href="http://www.sualab.com" target="_blank" rel="noopener noreferrer">
                    <img src={sualab} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a href="https://www.wanted.co.kr" target="_blank" rel="noopener noreferrer">
                    <img src={wanted} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a href="http://www.enpgames.co.kr" target="_blank" rel="noopener noreferrer">
                    <img src={enpgames} alt="" />
                  </a>
                </div>
                <div className="portfolio_item">
                  <a href="http://www.aprogen-pharm.com" target="_blank" rel="noopener noreferrer">
                    <img src={aprogenmedi} alt="" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Portfolio);
