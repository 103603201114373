import React from 'react';

const TextInput = props => {
  let formControl = 'form-control';
  if (props.touched && !props.valid) {
    formControl = 'form-control control-error';
  }
  return (
    <div className="form-group">
      <label>{props.label}</label>
      <span>
        ( <span> {props.value.length} </span> / {props.maxLength} )
      </span>
      <input type="text" className={formControl} {...props} />
      <div className="help-block with-errors">
        {props.touched && !props.valid ? (
          <ul className="list-unstyled">
            <li>{props['data-error']}</li>
          </ul>
        ) : (
          undefined
        )}
      </div>
    </div>
  );
};

export default TextInput;
