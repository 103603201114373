import React from 'react';
import axios from 'axios';

import * as config from 'config';
import FileItemList from './FileInput';
import TextInput from './TextInput';
import validate from './Validate';

const TYPE = 'PORTFOLIO';

// const formModel = {
//   title: 'title',
//   content: 'url',
//   file: null,
//   type: TYPE
// };

export default class PortfolioAdd extends React.Component {
  state = {
    file: [],
    formIsValid: false,
    formControls: {
      content: {
        name: 'content',
        label: 'URL',
        value: '',
        placeholder: 'http:// or https:// 가 반드시 포함되어야합니다.',
        valid: false,
        touched: false,
        dataError: 'URL min 3 max 255',
        validationRules: {
          maxLength: 255,
          minLength: 3
        }
      }
    }
  };

  async post(formModel) {
    const headers = {
      Authorization:
        'Bearer ' +
        localStorage.getItem('token')
    };
    try {
      let formData = new FormData();
      for (let name in formModel) {
        if (name === 'file') {
          for (const file of formModel[name]) {
            formData.append('image', file, file.name);
          }
        } else {
          formData.append(name, formModel[name]);
        }
      }
      const response = await axios
        .post(`${config.API_BOARD}`, formData, { headers })
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            this.props.history.push('/admin/list');
          }
        })
        .catch(response => {
          console.error(response);
        });
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {}

  handleAddItem = e => {
    const formModel = {
      title: this.state.formControls.content.value,
      content: TYPE,
      file: this.state.file.map(r => {
        return r.file;
      }),
      type: TYPE
    };
    this.post(formModel);
  };

  onFileChange = file => {
    this.setState({
      file
    });
  };

  handleDeletedFileRow = deletedFileRow => {
    this.setState({
      file: []
    });
  };

  changeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedControls = {
      ...this.state.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
    updatedControls[name] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    this.setState({
      formControls: updatedControls,
      formIsValid: formIsValid
    });
  };

  render() {
    return (
      <form>
        <TextInput
          name={this.state.formControls.content.name}
          label={this.state.formControls.content.label}
          placeholder={this.state.formControls.content.placeholder}
          value={this.state.formControls.content.value}
          onChange={this.changeHandler}
          touched={this.state.formControls.content.touched ? 1 : 0}
          valid={this.state.formControls.content.valid ? 1 : 0}
          data-error={this.state.formControls.content.dataError}
          minLength={this.state.formControls.content.validationRules.minLength}
          maxLength={this.state.formControls.content.validationRules.maxLength}
        />
        <div>* imageSize: 370x300</div>
        <FileItemList
          label="thumbnail"
          maxLength={1}
          file={this.state.file}
          onFileChange={this.onFileChange}
          handleDeletedFileRow={this.handleDeletedFileRow}
          accept="image/png, image/jpeg"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.handleAddItem}
          disabled={!this.state.formIsValid || !this.state.file.length > 0}
        >
          Confirm
        </button>
      </form>
    );
  }
}
