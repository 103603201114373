//No Underscore or Lo-Dash

export function getyyyyMMdd(time) {
  const x = new Date(time);
  const year = x.getFullYear().toString();
  let MM = (x.getMonth() + 1).toString();
  let dd = x.getDate().toString();
  if (dd.length === 1) {
    dd = '0' + dd;
  }
  if (MM.length === 1) {
    MM = '0' + MM;
  }
  const yyyyMMdd = `${year}-${MM}-${dd}`;
  // console.log(yyyyMMdd);
  return yyyyMMdd;
}

export function searchParams() {
  let match,
    pl = /\+/g,
    search = /([^&=]+)=?([^&]*)/g,
    decode = function(s) {
      return decodeURIComponent(s.replace(pl, ' '));
    },
    query = window.location.search.substring(1);

  let urlParams = {};
  while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);
  return urlParams;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function isEquivalent(a, b) {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

export function arrayDiff(a, b) {
  const diffIndexes = [];
  return a.filter(function(i) {
    if (b.indexOf(i) < 0) {
      diffIndexes.push(a.indexOf(i));
      return true;
    } else {
      return false;
    }
  });
}

export function removeDuplicates(arr) {
  return Object.keys(
    arr.reduce((acc, val) => {
      acc[JSON.stringify(val)] = 1;
      return acc;
    }, {})
  ).map((val, key, array) => JSON.parse(array[key]));
}

export function isNumberKey(event) {
  if (event.keyCode < 48 || event.keyCode > 57) {
    return false;
  }
  return true;
}

// columns = state.get('column').toJS();
// for (let rv of columns) {
//   let idx = res.data.findIndex(fr => {
//     return rv.board_id === fr.board_id;
//   });
//   if (idx !== -1) {
//     isEqul = true;
//     break;
//   }
// }
// if (isEqul) {
//   columns = columns;
// } else {
//   columns = [...columns, ...res.data];
// }

// let books = [
//   {
//     name: 'My Sister the Serial Killer',
//     author: 'Oyinkan Braithwaite'
//   },
//   {
//     name: 'Educated',
//     author: 'Tara Westover'
//   },
//   {
//     name: 'My Sister the Serial Killer',
//     author: 'Oyinkan Braithwaite'
//   }
// ];

// books = books.reduce(
//   (acc, v) => acc.filter(o => Object.keys(o).every(key => v[key] !== o[key])).concat(v),
//   []
// );

export function detectIE() {
  var ua = window.navigator.userAgent;
  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
