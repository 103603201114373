import React from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import axios from "axios";
import * as config from "config";

function tokenValid() {
  let valid = false;
  const hasToken = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : false;
  if (hasToken) {
    const headers = {
      Authorization: "Bearer " + hasToken,
    };
    axios
      .post(`${config.API_VAILD}`, null, { headers })
      .then(response => {
        console.log(response);
        if (response.status === 200) {
        }
      })
      .catch(response => {
        console.error(response);
      });
  }
  return valid;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const valid = localStorage.getItem("token") ? true : false;
      if (!valid) {
        alert("로그인 후 사용가능합니다.");
      }
      return valid ? <Component {...props} /> : <Redirect to="/admin/login" />;
    }}
  />
);
export default PrivateRoute;
