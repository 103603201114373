import React from 'react';
// import { Helmet } from 'react-helmet';
// import './style.scss';
import { Link, Element } from 'react-scroll';
import { injectIntl } from 'react-intl';
import components from 'components/Index';

class Compnay extends React.Component {
  constructor() {
    super();
    this.state = {
      isFixed: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll(e) {
    // document.documentElement.scrollTop
    const $breakWidth = 1024;
    const $mastheadHeight = 560;
    const $mastheadHeightMobile = 350;
    if (window.innerWidth > $breakWidth) {
      if (
        (document.querySelector('body').scrollTop || document.querySelector('html').scrollTop) >
        $mastheadHeight
      ) {
        this.setState({
          isFixed: true
        });
      } else {
        this.setState({
          isFixed: false
        });
      }
    } else {
      if (
        (document.querySelector('body').scrollTop || document.querySelector('html').scrollTop) >
        $mastheadHeightMobile
      ) {
        this.setState({
          isFixed: true
        });
      } else {
        this.setState({
          isFixed: false
        });
      }
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <div ref="iScroll" id="company">
        <components.Masthead addClass={'company'} title={'Company'} />
        <div className={this.state.isFixed ? 'nav_scroll fixed' : 'nav_scroll'}>
          <div className="content">
            <Link
              activeClass="active"
              to="Profile"
              offset={0}
              spy={true}
              smooth={true}
              duration={500}
            >
              <button>Profile</button>
            </Link>
            <Link
              activeClass="active"
              to="History"
              offset={0}
              spy={true}
              smooth={true}
              duration={500}
            >
              <button>History</button>
            </Link>
            <Link
              activeClass="active"
              to="Businesses"
              offset={0}
              spy={true}
              smooth={true}
              duration={500}
            >
              <button>Businesses</button>
            </Link>
          </div>
        </div>
        <div className="company">
          <ul className="content__list">
            <li>
              <Element name="Profile">
                <div className="content">
                  <div className="content__item">
                    <div className="content__head">TRUBEN INVESTMENT Co.,Ltd.</div>
                    <div className="content__detail">
                      <div className="half">
                        <div className="text">{intl.formatMessage({ id: 'company_profile1' })}</div>
                      </div>
                      <div className="half ">
                        <div className="text">{intl.formatMessage({ id: 'company_profile2' })}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Element>
            </li>
            <li>
              <Element name="History">
                <div className="content ">
                  <div className="content__item">
                    <div className="content__head">History</div>
                    <div className="content__detail">
                      <div className="half">
                        <div className="text">
                          {intl.formatMessage({ id: 'company_history1' })}
                          <br />
                          <br />
                          {intl.formatMessage({ id: 'company_history2' })}
                        </div>
                      </div>
                      <div className="history__table">
                        <table>
                          <tbody>
                            <tr>
                              <th>2017</th>
                              <td>
                                <p>MAY</p>
                                <div>- {intl.formatMessage({ id: 'company_history_may' })}</div>
                              </td>
                            </tr>
                            <tr>
                              <th>2015</th>
                              <td>
                                <p>DECEMBER</p>
                                <div>
                                  - {intl.formatMessage({ id: 'company_history_december' })}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>2014</th>
                              <td>
                                <p>NOVEMBER</p>
                                <div>- {intl.formatMessage({ id: 'company_history_november' })}</div>
                              </td>
                            </tr>
                            <tr>
                              <th></th>
                              <td>
                                <p>APRIL</p>
                                <div>- {intl.formatMessage({ id: 'company_history_april' })}</div>
                              </td>
                            </tr>
                            <tr>
                              <th>2013</th>
                              <td>
                                <p>JULY</p>
                                <div>- {intl.formatMessage({ id: 'company_history_july' })}</div>
                              </td>
                            </tr>
                            <tr>
                              <th>2012</th>
                              <td>
                                <p>JANUARY</p>
                                <div>- {intl.formatMessage({ id: 'company_history_january' })}</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Element>
            </li>
            <li>
              <Element name="Businesses">
                <div className="content">
                  <div className="content__item">
                    <div className="content__head">{intl.formatMessage({ id: 'company_businesses1' })}</div>
                    <div className="content__detail">
                      <div className="indicator">{intl.formatMessage({ id: 'company_private_equity' })}</div>
                    </div>
                  </div>

                  <div className="content__item">
                    <div className="content__head">{intl.formatMessage({ id: 'company_businesses2' })}</div>
                    <div className="content__detail">
                      <div className="indicator">
                      {intl.formatMessage({ id: 'company_infrastructure' })}
                      </div>
                    </div>
                  </div>
                </div>
              </Element>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default injectIntl(Compnay);
