import React, { Component } from "react";
import Axios from "axios";
import DateFns from "date-fns";
import * as config from "config";
import Calendar from "../Calendar/Calendar";
import Editor from "../Editor/Editor";
import CalendarIcon from "assets/icon/outline-calendar_today-24px.svg";
import decode from "jwt-decode";
export class MakeNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      contnet: "",
      date: DateFns.format(new Date(), "YYYY-MM-DD HH:mm:ss"),
      toggle: false,
    };
    // this.nowDate = this.nowDate.bind(this, false);
  }

  componentWillMount() {
    const decoded = decode(localStorage.getItem("token"));
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    if (decoded) {
      if (decoded.exp <= secondsSinceEpoch) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    } else {
      localStorage.removeItem("token");
      window.location.reload();
    }
  }

  getDate = date => {
    this.setState({
      date,
    });
  };

  handleChange = e => {
    if (e.target.id === "content") {
      this.setState({
        content: e.target.value,
      });
    } else {
      this.setState({
        title: e.target.value,
      });
    }
  };
  dateToggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  submit = async e => {
    e.preventDefault();
    if (this.state.title !== "" && this.state.content !== undefined) {
      if (window.confirm("게시글을 작성하시겠습니까?")) {
        const Form = new FormData();
        Form.append("title", this.state.title);
        Form.append("type", "NOTICE");
        Form.append("content", this.state.content);
        Form.append("board_created", this.state.date);

        Axios.post(`${config.API_BOARD}`, Form, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then(res => {
            this.props.history.replace("/admin/notice");
          })
          .catch(err => {});
      }
    } else if (this.state.content === undefined && this.state.title !== "") {
      alert("내용을 적어주세요");
    } else if (this.state.title === "" && this.state.content !== "") {
      alert("제목을 적어주세요");
    } else {
      alert("제목과 내용을 적어주세요");
    }
  };
  getContent = content => {
    this.setState({ content });
  };
  render() {
    return (
      <div className="admin-notice">
        <form onSubmit={this.submit}>
          <p className="title">새 글 등록</p>
          <div className="input">
            <span className="value">제목</span>
            <input
              id="title"
              onChange={this.handleChange}
              value={this.state.title}
            />
          </div>

          <hr />

          <div className="input">
            <span className="value">등록일</span>
            <div className="date-box-pre">
              <div className="date-box" onClick={this.dateToggle}>
                <img src={CalendarIcon} alt="" className="date-icon" />
              </div>
              <Calendar
                toggle={this.state.toggle}
                dateToggle={this.dateToggle}
                getDate={this.getDate}
              />
            </div>
          </div>

          <hr />

          <span className="value">내용</span>

          <Editor getContent={this.getContent} data={this.state.content} />

          <hr />
          <button className="notice-submit-btn">등록하기</button>
        </form>
      </div>
    );
  }
}

export default MakeNotice;
