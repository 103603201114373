import React from "react";
import DateFns from "date-fns";

class DateText extends React.Component {
  //* show date
  render() {
    const { toggle } = this.props;
    return (
      <span className="DateText" onClick={() => toggle()}>
        {this.props.formattedDate.substring(0, 10)}
      </span>
    );
  }
}

function formatDateToDay(date) {
  //* 날짜 형태 지정
  return DateFns.format(date, "DD");
}

function clone(d) {
  return new Date(d.getTime());
}

function getCurrentDate(thisDay) {
  //* 현재 시간
  if (thisDay) {
    return thisDay;
  } else {
    return new Date();
  }
}

function DateList(props) {
  let dateList = props.dates.map((date, index) => {
    return (
      <div
        onClick={e => props.setSelectedDate(date, e)}
        className={
          date.isCurrentMonth ? "dateButton thisMon" : "dateButton thisMon"
        }
        key={index}
      >
        {formatDateToDay(date.date)}
      </div>
    );
  });
  return <div className={"calendar"}>{dateList}</div>;
}

class DayPickerHeader extends React.Component {
  render() {
    return (
      <header className={"header"}>
        <span onClick={this.props.previousMonth}>&lt;</span>
        <p>{this.props.currentYear + " . " + this.props.currentMonthLabel}</p>
        <span onClick={this.props.nextMonth}>&gt;</span>
      </header>
    );
  }
}

class Headings extends React.Component {
  render() {
    return (
      <div className={"headings"}>
        {this.props.dayLabels.map((day, index) => {
          return (
            <span className={"dayLabel"} key={index}>
              {day}
            </span>
          );
        })}
      </div>
    );
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      today: props.currDate || new Date(),
      selectedDate: props.currDate || new Date(),
      currentDateCursor: new Date(),
      dayLabels: ["S", "M", "T", "W", "Th", "F", "S"],
      monthLabels: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      toggle: true,
    };
  }

  curMonth = () => {
    return this.state.currentDateCursor.getMonth();
  };

  curYear = () => {
    return this.state.currentDateCursor.getFullYear();
  };

  curMonthLabel = () => {
    return this.state.monthLabels[this.curMonth()];
  };

  selectedMonth = () => {
    return this.state.selectedDate.getMonth();
  };

  selectedMonthLabel = () => {
    return this.state.monthLabels[this.selectedMonth()];
  };

  datesArray = () => {
    const date = this.state.currentDateCursor;
    const startOfMonth = DateFns.startOfMonth(date);
    const endOfMonth = DateFns.endOfMonth(date);
    const days = DateFns.eachDay(startOfMonth, endOfMonth);
    const dates = days.map(day => ({
      date: day,
      isCurrentMonth: DateFns.isSameMonth(
        new Date(this.curYear(), this.curMonth()),
        day
      ),
      isToday: DateFns.isToday(day),
      isSelected: DateFns.isSameDay(day, this.state.selectedDate),
    }));

    // get days from last month
    let previousMonthCursor = DateFns.lastDayOfMonth(DateFns.addMonths(date, -1));
    const begIndex = DateFns.getDay(days[0]);
    for (let i = begIndex; i > 0; i--) {
      dates.unshift({
        date: previousMonthCursor,
        isCurrentMonth: false,
        isToday: DateFns.isToday(previousMonthCursor),
        isSelected: DateFns.isSameDay(this.state.selectedDate, previousMonthCursor),
      });
      previousMonthCursor = DateFns.addDays(previousMonthCursor, -1);
    }

    // get days from next month
    let daysNeededAtEnd = dates.length % 7 > 0 ? 7 - (dates.length % 7) : 0;
    let nextMonthCursor = DateFns.addMonths(date, 1);
    nextMonthCursor = DateFns.setDate(nextMonthCursor, 1);
    for (let i = 1; i <= daysNeededAtEnd; ++i) {
      dates.push({
        date: nextMonthCursor,
        isCurrentMonth: false,
        isToday: DateFns.isToday(nextMonthCursor),
        isSelected: DateFns.isSameDay(this.state.selectedDate, nextMonthCursor),
      });
      nextMonthCursor = DateFns.addDays(nextMonthCursor, 1);
    }

    return dates;
  };

  componentDidMount() {
    if (this.props.startDate) {
      this.setState({
        currentDateCursor: this.props.startDate,
        selectedDate: this.props.startDate,
      });
    }
  }

  setSelectedDate = (date, e, toggle) => {
    this.props.changeCurrentDate(date, e);
    this.setState({
      selectedDate: date.date,
    });
    this.props.toggle();
  };

  nextMonth = () => {
    let date = clone(this.state.currentDateCursor);
    this.setState({
      currentDateCursor: DateFns.addMonths(date, 1),
    });
  };

  previousMonth = () => {
    let date = clone(this.state.currentDateCursor);
    this.setState({
      currentDateCursor: DateFns.addMonths(date, -1),
    });
  };
  render() {
    let dates = this.datesArray();
    return (
      <div className={"dayPicker"}>
        <DayPickerHeader
          nextMonth={this.nextMonth}
          previousMonth={this.previousMonth}
          currentMonthLabel={this.curMonthLabel()}
          currentYear={this.curYear()}
        />
        <Headings dayLabels={this.state.dayLabels} />
        <DateList setSelectedDate={this.setSelectedDate} dates={dates} />
      </div>
    );
  }
}

export default class DayPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: getCurrentDate(this.props.thisDay),
      toggle: false,
    };
  }

  getFormattedDate = () => {
    return DateFns.format(this.state.currentDate, "YYYY-MM-DD 11:mm:ss");
  };

  changeCurrentDate = async (date, e) => {
    await this.setState({
      currentDate: date.date,
    });
    await this.nowDate();
  };
  toggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };
  nowDate = () => {
    this.props.getDate(this.getFormattedDate());
  };
  render() {
    return (
      <div className="input-box">
        <DateText
          toggle={this.props.dateToggle}
          formattedDate={this.getFormattedDate()}
        />
        {this.props.toggle ? (
          <Calendar
            currDate={this.state.currentDate}
            changeCurrentDate={this.changeCurrentDate}
            toggle={this.props.dateToggle}
          />
        ) : null}
      </div>
    );
  }
}
