import React from 'react';

export default class Slider extends React.Component {
  constructor(props) {
    super(props);
    const en_list = [
      {
        title: 'Investing in Sustainable Competitive Advantages',
        desc:
          'Leveraging a deep network of relationships throughout the global industries, Truben seeks to cultivate proprietary or specialized investment opportunities not typically available to generalist investors.'
      },
      {
        title: 'Partnership with Proven Leaders',
        desc:
          'Partnering with proven leaders drawn from across the global industries and various businesses, Truben and its management teams work in concert to establish and sustain differentiated competitive advantages in their respective markets.'
      },
      {
        title: 'Providing Innovative Financial Solutions',
        desc:
          'Through a comprehensive approach across our firm, Truben seeks to provide innovative financial solutions and investment vehicles to help our fund investors achieve their investment goals.'
      }
    ];
    const ko_list = [
      {
        title: `지속 가능한 경쟁 우위 대상에 
        대한 투자`,
        desc:
          `트루벤은 글로벌 산업 전반에 걸친 깊이 있는 협력관계를 바탕으로 
          일반 투자자에게는 제공되지 않는 독점적이고 전문성 높은 투자 기회를 발굴하고 있습니다.`
      },
      {
        title: `검증된 업계 리더들과의 
        파트너십`,
        desc:
          `트루벤은 각각의 시장에서 차별화된 경쟁 우위를 선점하고 유지하기 위해 글로벌 산업 및 다양한 사업 분야에서 성과가 검증된 리더들과 
          협력하고 있습니다.`
      },
      {
        title: '혁신적인 금융 솔루션 제공',
        desc:
          `트루벤은 포괄적인 접근 방식을 통해 혁신적인 금융 솔루션과 
          투자 수단을 제공함으로써 투자자들이 투자 목표를 달성할 수 있도록 
          지원하고 있습니다.`
      }
    ];

    let arr = [];
    switch (this.props.locale) {
      case 'en':
        arr = en_list;
        break;
      case 'ko':
        arr = ko_list;
        break;
      default:
        break;
    }
    this.state = {
      arr,
      idx: 0,
      offset: { transform: `translateX(0px)` }
    };
  }

  handlePrev = e => {
    let offset = this.refs.slider.offsetWidth / this.state.arr.length;
    const idx = this.state.idx - 1;
    if (idx > -1 && idx < this.state.arr.length) {
      offset = offset * idx;
      this.setState({
        idx,
        offset: { transform: `translateX( -${offset}px)` }
      });
    } else {
      this.setState({
        idx: this.state.arr.length,
        offset: { transform: `translateX( -${this.state.arr.length - 1 * offset}px)` }
      });
    }
  };

  handleNext = e => {
    let offset = this.refs.slider.offsetWidth / this.state.arr.length;
    const idx = this.state.idx + 1;
    if (idx < this.state.arr.length) {
      offset = offset * idx;
      this.setState({
        idx,
        offset: { transform: `translateX( -${offset}px)` }
      });
    } else {
      this.setState({
        idx: 0,
        offset: { transform: `translateX( 0px)` }
      });
    }
  };

  render() {
    return (
      <div className="slider__container style">
        <div className="slider__wrap">
          <div className="slider__name">PORTFOLIO</div>
          <div className="items-container" ref="slider" style={this.state.offset}>
            {this.state.arr.map((r, i) => {
              return (
                <div className="item" key={i}>
                  <div className="item__content">
                    <div className="title">{r.title}</div>
                    <div className="desc">{r.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ul className="pagination">
          <li className="prev" onClick={this.handlePrev}>
            {/* &lt; */}
          </li>
          <li className="next" onClick={this.handleNext}>
            {/* &gt; */}
          </li>
        </ul>
      </div>
    );
  }
}
