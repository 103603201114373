import React from 'react';
// import { Helmet } from 'react-helmet';
// import './style.scss';
import components from 'components/Index';
import { Link, Element } from 'react-scroll';
import { injectIntl } from 'react-intl';

class Strategy extends React.Component {
  constructor() {
    super();
    this.state = {
      isFixed: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll(e) {
    const $breakWidth = 1024;
    const $mastheadHeight = 560;
    const $mastheadHeightMobile = 350;
    if (window.innerWidth > $breakWidth) {
      if (
        (document.querySelector('body').scrollTop || document.querySelector('html').scrollTop) >
        $mastheadHeight
      ) {
        this.setState({
          isFixed: true
        });
      } else {
        this.setState({
          isFixed: false
        });
      }
    } else {
      if (
        (document.querySelector('body').scrollTop || document.querySelector('html').scrollTop) >
        $mastheadHeightMobile
      ) {
        this.setState({
          isFixed: true
        });
      } else {
        this.setState({
          isFixed: false
        });
      }
    }
  }
  render() {
    const { intl } = this.props;
    return (
      <div id="strategy">
        <components.Masthead addClass={'strategy'} title={'Strategy'} />
        <div className="strategy">
          {/* start */}
          <div className={this.state.isFixed ? 'nav_scroll fixed' : 'nav_scroll'}>
            <div className="content">
              <Link
                activeClass="active"
                to="Strategy"
                offset={0}
                spy={true}
                smooth={true}
                duration={500}
              >
                <button>Investment Strategy</button>
              </Link>
              <Link
                activeClass="active"
                to="Approach"
                offset={0}
                spy={true}
                smooth={true}
                duration={500}
              >
                <button>Our Approach</button>
              </Link>
            </div>
          </div>
          <Element name="Strategy">
            <ul className="content__list">
              <li>
                <div className="content">
                  <div className="content__item">
                    <div className="content__head">
                      {intl.formatMessage({ id: 'strategy_overview' })}
                    </div>
                    <div className="content__detail">
                      <div className="full">
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_overview_desc' })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="content">
                  <div className="content__item">
                    <div className="content__head">
                      {intl.formatMessage({ id: 'strategy_opportunities' })}
                    </div>
                    <div className="content__detail">
                      <div className="full">
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_opportunities_desc' })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="content">
                  <div className="content__item">
                    <div className="content__head">
                      {intl.formatMessage({ id: 'strategy_investing' })}
                    </div>
                    <div className="content__detail">
                      <div className="full">
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_investing_desc' })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </Element>
          <Element name="Approach">
            <ul className="content__list__reverse">
              <li>
                <div className="content">
                  <div className="content__item">
                    <div className="content__head">{intl.formatMessage({ id: 'strategy_approach_title' })}</div>
                    <div className="content__detail">
                      <div className="full">
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_approach' })}
                        </div>
                      </div>
                    </div>
                    {/*}
                    <div className="content__detail">
                      <div className="half">
                        <div className="content__head">Expertise in creating value</div>
                        <div className="text">
                          We are active investors that partner with our portfolio company management teams to achieve growth
                          and improve productivity.
                        </div>
                      </div>
                      <div className="half">
                        <div className="content__head">Stakeholder engagement</div>
                        <div className="text">
                          We regularly engage with a range of stakeholders, including individuals, investors, global
                          organizations and community groups, and portfolio company management and employees who are affected
                          by our business decisions and can contribute to our success.
                        </div>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </li>
              <li>
                <div className="content">
                  <div className="content__item">
                    <div className="content__detail">
                      <div className="half">
                        <div className="content__head">
                          {intl.formatMessage({ id: 'strategy_approach_expertise' })}
                        </div>
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_approach_expertise_desc' })}
                        </div>
                      </div>
                      <div className="half ">
                        <div className="content__head">
                          {intl.formatMessage({ id: 'strategy_approach_engagement' })}
                        </div>
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_approach_engagement_desc' })}
                        </div>
                      </div>
                    </div>
                    <div className="content__detail">
                      <div className="half">
                        <div className="content__head">
                          {intl.formatMessage({ id: 'strategy_approach_investment' })}
                        </div>
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_approach_investment_desc' })}
                        </div>
                      </div>
                      <div className="half ">
                        <div className="content__head">
                          {intl.formatMessage({ id: 'strategy_approach_returns' })}
                        </div>
                        <div className="text">
                          {intl.formatMessage({ id: 'strategy_approach_returns_desc' })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </Element>
        </div>
      </div>
    );
  }
}
export default injectIntl(Strategy);
