import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// lt 11 load below core-js
// import 'core-js/features/array/find';
// import 'core-js/features/array/includes';
// import 'core-js/features/number/is-nan';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// router
import { BrowserRouter } from 'react-router-dom';
//i18n
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ko from 'react-intl/locale-data/ko';
import locale from 'assets/locale';

addLocaleData([...en, ...ko]);
const defaultLang = localStorage.getItem('lang') || 'en';
console.log('locale', defaultLang);

ReactDOM.render(
  <BrowserRouter>
    <IntlProvider locale={defaultLang} messages={locale[defaultLang]}>
      <App />
    </IntlProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
