import React, { Component } from 'react';

export default class LayerPopup extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
    };
  }
  render() {
    return (
      <div>
        {this.state.show ? (
          <div className="layer__popup">
            <div className="head">
              <button
                className="close__button"
                onClick={() => this.setState({ show: false })}
              />
            </div>
            {/* <img src={require('assets/img/tmp/stock.jpg')} alt='' /> */}
            <img src="popup.jpg" alt="" />
          </div>
        ) : (
          undefined
        )}
      </div>
    );
  }
}
