import React, { Component } from "react";
import * as config from "config";
import Axios from "axios";
import DateFns from "date-fns";
import Calendar from "../Calendar/Calendar";
import Editor from "../Editor/Editor";
import CalendarIcon from "assets/icon/outline-calendar_today-24px.svg";
import decode from "jwt-decode";
class modifyNotice extends Component {
  state = {
    title: "",
    date: "",
    content: "",
    toggle: false,
  };
  componentWillMount() {
    const decoded = decode(localStorage.getItem("token"));
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    if (decoded) {
      if (decoded.exp <= secondsSinceEpoch) {
        localStorage.removeItem("token");
        window.location.reload();
      } 
    } else {
      localStorage.removeItem("token");
      window.location.reload();
    }
    Axios.get(`${config.API_BOARD}/${this.props.match.params.id}`).then(res => {
      this.setState({
        date: DateFns.format(res.data.article.board_created, "YYYY-MM-DD HH:mm:ss"),
        title: res.data.article.title,
        content: res.data.article.content,
      });
    });
  }
  componentDidMount() {}

  handleFileSelect = e => {
    e.preventDefault();
    this.fileSelector.click();
  };

  getDate = date => {
    this.setState({
      date,
    });
  };
  handleChange = e => {
    if (e.target.id === "content") {
      this.setState({
        content: e.target.value,
      });
    } else {
      this.setState({
        title: e.target.value,
      });
    }
  };
  submit = async e => {
    e.preventDefault();
    if (this.state.title !== "" && this.state.content !== undefined) {
      if (window.confirm("게시글을 수정하시겠습니까?")) {
        const Form = new FormData();
        Form.append("title", this.state.title);
        Form.append("type", "NOTICE");
        Form.append("content", this.state.content);
        Form.append("board_created", this.state.date);

        Axios.put(`${config.API_BOARD}/${this.props.match.params.id}`, Form, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then(async res => {
            // await this.setState({
            //   title: "",
            //   content: "",
            //   date: "",
            // });
            localStorage.setItem("IE_notice", 1);
            await this.props.history.replace("/admin/notice");
          })
          .catch(err => {});
      }
    } else if (this.state.content === undefined && this.state.title !== "") {
      alert("내용을 적어주세요");
    } else if (this.state.title === "" && this.state.content !== "") {
      alert("제목을 적어주세요");
    } else {
      alert("제목과 내용을 적어주세요");
    }
  };

  getContent = content => {
    this.setState({ content });
  };
  dateToggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  delete = () => {
    if (window.confirm("게시글을 삭제하시겠습니까?")) {
      Axios.delete(`${config.API_BOARD}/${this.props.match.params.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then(this.props.history.replace("/admin/notice"))
        .catch(err => {
          localStorage.removeItem("token");
          window.location.reload();
        });
    }
  };
  render() {
    return (
      <div className="admin-notice">
        <form onSubmit={this.submit}>
          <p className="title">글 수정</p>
          <div className="input">
            <span className="value">제목</span>
            <input
              id="title"
              onChange={this.handleChange}
              value={this.state.title}
            />
          </div>
          <hr />
          <div className="input">
            <span className="value">등록일</span>
            <div className="date-box-pre">
              <div className="date-box" onClick={this.dateToggle}>
                <img src={CalendarIcon} alt="" className="date-icon" />
              </div>
              {this.state.date ? (
                <Calendar
                  toggle={this.state.toggle}
                  dateToggle={this.dateToggle}
                  getDate={this.getDate}
                  thisDay={this.state.date}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <hr />
          <div>
            <span className="value">내용</span>
            {this.state.content !== "" ? (
              <Editor getContent={this.getContent} data={this.state.content} />
            ) : (
              <></>
            )}
          </div>
          <button className="notice-submit-btn" type="submit">
            저장하기
          </button>
          <input
            type="button"
            className="notice-submit-btn delete"
            value="삭제하기"
            onClick={this.delete}
          />
        </form>
      </div>
    );
  }
}
export default modifyNotice;
