import React from 'react';
import Axios from 'axios';
import * as config from 'config';
// import { Helmet } from 'react-helmet';
// import './style.scss';
import components from 'components/Index';
import searchIcon from 'assets/icon/outline-search-24px.svg';
// import mapW from 'assets/img/map-w.png';
// import mapM from 'assets/img/map-m.png';

import { injectIntl } from 'react-intl';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notice: [],
      toggle: false,
      detail: null,
      nextItem: null,
      prevItem: null,
      pageCount: [],
      itemTotal: null,
      nowPage: 1,
      searchValue: '',
      searchItem: [],
      searchCount: [],
      pageStatus: true,
    };
  }

  componentWillMount() {
    Axios.get(
      `${config.API_BOARD}?_limit=10&_page=${this.state.nowPage}&type=NOTICE&_order=board_created&_sort=DESC`,
    ).then(res => {
      let pageArray = [];
      for (let i = 1; i <= Math.ceil(res.headers['x-total-count'] / 10); i++) {
        pageArray.push(i);
      }
      this.setState({
        notice: res.data,
        pageCount: pageArray,
      });
    });
  }
  //* detail 이동 or 목록으로 이동
  handleToggle = data => {
    const id = Number(data.target.id);
    this.props.history.push(`/detail/${id}`);
  };

  //* Pagination start
  Pagination = page => {
    const id = Number(page.target.id);
    if (id !== this.state.nowPage && this.state.pageStatus) {
      Axios.get(`${config.API_BOARD}?_limit=10&_page=${id}&type=NOTICE`).then(
        res => {
          this.setState({
            notice: res.data,
            nowPage: id,
          });
        },
      );
    } else if (!this.state.pageStatus) {
      Axios.get(
        `${config.API_SEARCH}?keyword=${this.state.searchValue}&_limit=10&_page=${id}&type=NOTICE`,
      ).then(res => {
        this.setState({
          searchItem: res.data,
          nowPage: id,
        });
      });
    }
  };
  SearchInputChange = data => {
    const value = data.target.value;
    this.setState({
      searchValue: value,
    });
  };
  Search = e => {
    e.preventDefault();
    if (this.state.searchValue !== '') {
      Axios.get(
        `${config.API_SEARCH}?keyword=${this.state.searchValue}&_limit=10&_page=1&type=NOTICE`,
      )
        .then(res => {
          let pageArray = [];
          for (
            let i = 1;
            i <= Math.ceil(res.headers['x-total-count'] / 10);
            i++
          ) {
            pageArray.push(i);
          }
          this.setState({
            searchItem: res.data,
            pageStatus: false,
            searchCount: pageArray,
            nowPage: 1,
          });
        })
        .catch(err => {
          this.setState({
            searchItem: [],
            pageStatus: false,
          });
        });
    } else {
      Axios.get(
        `${config.API_BOARD}?_limit=10&_page=${this.state.nowPage}&type=NOTICE`,
      )
        .then(res => {
          let pageArray = [];
          for (
            let i = 1;
            i <= Math.ceil(res.headers['x-total-count'] / 10);
            i++
          ) {
            pageArray.push(i);
          }
          this.setState({
            searchItem: res.data,
            pageStatus: false,
            searchCount: pageArray,
            nowPage: 1,
          });
        })
        .catch(err => {
          this.setState({
            searchItem: [],
            pageStatus: false,
          });
        });
    }
  };
  render() {
    const { intl } = this.props;
    const {
      notice,
      pageCount,
      searchItem,
      searchCount,
      pageStatus,
    } = this.state;
    console.log(notice);
    return (
      <div id="contact" className="contact">
        <components.Masthead addClass={'contact'} title={'Notice'} />
        <div className="content contact__wrap">
          <div className="desc">
            {intl.formatMessage({ id: 'contact_address' })}
          </div>
          {/* <a className="mailto" href={'mailto:' + this.props.email}> */}
          {/* <a className="mailto" href="mailto:Contact@email.com"> */}
          {/* <a className="mainto">contact@email.com</a> */}
          <div className="tel">Phone: 02. 6137. 9600</div>
          <br />
          <div className="fax">Fax: 02. 6291. 1181</div>
          <div className="map">{/* <img src={mapW} alt="" /> */}</div>

          {/* {toggle && detail ? (
            <div className="notice-detail-box">
              <h4 className="notice-title">
                {intl.formatMessage({ id: "contact_board_title" })}
              </h4>
              <components.Notcie_detail
                data={detail}
                toggle={this.handleToggle}
                change={this.ChangeDetail}
                nextItem={nextItem}
                prevItem={prevItem}
              />
            </div>
          ) : ( */}
          <div className="notice">
            <h4 className="notice-title">
              {intl.formatMessage({ id: 'contact_board_title' })}
            </h4>
            <div className="notice-search">
              <form onSubmit={this.Search}>
                <input
                  value={this.state.searchValue}
                  onChange={this.SearchInputChange}
                />
                <div>
                  <button type="submit">
                    <img src={searchIcon} alt="" />
                  </button>
                </div>
              </form>
            </div>
            <div className="notice-item-head">
              <div className="notice-item-index">
                <span>No</span>
              </div>
              <div>
                <span>제목</span>
              </div>
              <div className="notice-item-date-box">
                <span>등록일</span>
              </div>
            </div>
            {this.state.pageStatus ? (
              notice.length > 0 ? (
                notice.map((res, index) => (
                  <components.Notice
                    key={res.board_id}
                    notice={res}
                    toggle={this.handleToggle}
                    status={pageStatus}
                    index={index}
                  />
                ))
              ) : (
                <div>
                  <div className="notice-Letter">
                    <p>작성된 글이 없습니다.</p>
                  </div>
                </div>
              )
            ) : searchItem.length > 0 ? (
              searchItem.map((res, index) => (
                <components.Notice
                  key={res.board_id}
                  notice={res}
                  toggle={this.handleToggle}
                  status={pageStatus}
                  index={index}
                />
              ))
            ) : (
              <components.Notice notice={'non'} status={pageStatus} />
            )}

            <ul className="notice-pagination">
              {this.state.nowPage > 1 ? (
                <span onClick={this.Pagination}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-left"
                    className="svg-inline--fa fa-chevron-left fa-w-10"
                    id={
                      this.state.nowPage > 1
                        ? this.state.nowPage - 1
                        : this.state.nowPage
                    }
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      id={
                        this.state.nowPage > 1
                          ? this.state.nowPage - 1
                          : this.state.nowPage
                      }
                      fill="black"
                      d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                    />
                  </svg>
                </span>
              ) : (
                <span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-left"
                    className="svg-inline--fa fa-chevron-left fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="#9e9e9e"
                      d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                    />
                  </svg>
                </span>
              )}
              {pageStatus ? (
                pageCount.length === 0 ? (
                  <div>1</div>
                ) : (
                  pageCount.map(res => (
                    <div
                      key={res}
                      className={this.state.nowPage == res ? 'active' : ''}
                      onClick={this.Pagination}
                      id={res}
                    >
                      {res}
                    </div>
                  ))
                )
              ) : searchCount.length === 0 ? (
                <div>1</div>
              ) : (
                searchCount.map(res => (
                  <div
                    key={res}
                    className={this.state.nowPage == res ? 'active' : ''}
                    onClick={this.Pagination}
                    id={res}
                  >
                    {res}
                  </div>
                ))
              )}
              {this.state.nowPage < pageCount[pageCount.length - 1] ? (
                <span onClick={this.Pagination} id={this.state.nowPage + 1}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-right"
                    className="svg-inline--fa fa-chevron-right fa-w-10 "
                    id={this.state.nowPage + 1}
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      id={this.state.nowPage + 1}
                      fill="black"
                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    />
                  </svg>
                </span>
              ) : (
                <span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-right"
                    className="svg-inline--fa fa-chevron-right fa-w-10 "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="#9e9e9e"
                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    />
                  </svg>
                </span>
              )}
            </ul>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  }
}
export default injectIntl(Contact);
