import React from 'react';

const Footer = props => (
  <div className="footer">
    <div className="content">
      <div className="footer__wrap">
        <div className="footer__main">
          {/*
          <div>
            Truben Investment
            <br /> 45F Three IFC 10 Gukjegeumyoung-ro
            <br /> Yeongdeungpo-gu seoul 07326 Korea
          </div>
          */}
          <div>2019 © Truben Investment All rights reserved.</div>
        </div>
        <div className="footer__sub">
          The information and services provided on this website are for your informational purposes
          only. Nothing contained in this site constitutes an offer to sell or a solicitation of an
          offer to buy any interest in any investment vehicle managed by Truben Investment or any
          company in which Truben Investment or its related partnerships or corporations, or the
          partners, agents have invested. Nothing contained herein constitutes professional, tax or
          financial advice nor does any information contained herein constitute a comprehensive or
          complete statement of the matters discussed or the law relating thereto. Truben Investment
          is not responsible for any errors or omissions, or for the results obtained from the use
          of the information contained in this site. Certain links in this site connect to other
          websites maintained by third parties over whom Truben Investment has no control and Truben
          Investment makes no representations as to the accuracy or any other aspect of information
          contained in other websites.
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
