import React from 'react'

function Contact_Notice (notice, toggle, status, index) {
  if (notice.status === false && notice.notice === 'non') {
    return (
      <div>
        <div className='notice-Letter'>
          <p>검색된 글이 없습니다.</p>
        </div>
      </div>
    )
  } else {
    return (
      <div
        onClick={notice.toggle}
        className={notice.index === 0 ? 'notice-items first-item' : 'notice-items'}
        id={notice.notice.board_id}
      >
        <div className='notice-item-info'>
          <div id={notice.notice.board_id} className='notice-item-id'>
            <span onClick={notice.toggle} id={notice.notice.board_id}>
              {notice.index + 1}
            </span>
          </div>
          <div id={notice.notice.board_id} className='notice-item-title'>
            <span
              onClick={notice.toggle}
              className='notice-item-title'
              id={notice.notice.board_id}
            >
              {notice.notice.title}
            </span>
          </div>
        </div>
        <div id={notice.notice.board_id} className='notice-item-date'>
          <span
            onClick={notice.toggle}
            className='notice-item-date'
            id={notice.notice.board_id}
          >
            {notice.notice.board_created.substring(0, 10)}
          </span>
        </div>
      </div>
    )
  }
}

export default Contact_Notice

//  notice.map((res, index) => (
//                   <components.Notice
//                     key={res.board_id}
//                     notice={res}
//                     toggle={this.handleToggle}
//                     status={pageStatus}
//                     index={index}
//                   />
//                 ))
