import React, { Component } from 'react';
import Axios from 'axios';
import * as config from 'config';
import next from 'assets/icon/baseline-expand_less-24px.svg';
import prev from 'assets/icon/baseline-expand_more-24px.svg';
import components from 'components/Index';
import { injectIntl } from 'react-intl';

export class Contact_Notice_detail extends Component {
  state = {
    title: '',
    content: '',
    board_created: '',
    nextItem: null,
    prevItem: null,
  };

  nextDetail = () => {
    this.props.history.push(`/detail/${this.state.nextItem.board_id}`);
    window.location.reload();
  };
  prevDetail = () => {
    this.props.history.push(`/detail/${this.state.prevItem.board_id}`);
    window.location.reload();
  };
  moveList = () => {
    this.props.history.push(`/contact`);
  };

  componentWillMount() {
    Axios.get(`${config.API_BOARD}/${this.props.match.params.id}`).then(res => {
      this.setState({
        title: res.data.article.title,
        content: res.data.article.content,
        board_created: res.data.article.board_created,
        nextItem: res.data.list.next,
        prevItem: res.data.list.prev,
      });
    });
  }

  render() {
    const { intl } = this.props;
    console.log(this.state.prevItem, this.state.nextItem);
    return (
      <div id="contact" className="contact">
        <components.Masthead addClass={'contact'} title={'Notice'} />
        <div className="content contact__wrap">
          <div className="desc">
            {intl.formatMessage({ id: 'contact_address' })}
          </div>
          {/* <a className="mailto" href={'mailto:' + this.props.email}> */}
          {/* <a className="mailto" href="mailto:Contact@email.com"> */}
          {/* <a className="mainto">contact@email.com</a> */}
          <div className="tel">Phone: 02. 6137. 9600</div>
          <br />
          <div className="fax">Fax: 02. 6291. 1181</div>
          <div className="map">{/* <img src={mapW} alt="" /> */}</div>

          <div className="notice-detail-box">
            <div className="notice-detail">
              <h4 className="notice-title detail-title">
                {intl.formatMessage({ id: 'contact_board_title' })}
              </h4>
              <div className="notice-detail-head">
                <p className="notice-detail-title">{this.state.title}</p>
                <p className="notice-detail-date">
                  {this.state.board_created.substring(0, 10)}
                </p>
              </div>
              <hr />
              <div
                className="notice-detail-content"
                dangerouslySetInnerHTML={{ __html: this.state.content }}
              />
              <div className="notice-detail-bottom">
                <div className="notice-detail-nav">
                  {this.state.prevItem !== null ? (
                    <div onClick={this.prevDetail}>
                      <img
                        alt=""
                        src={next}
                        className="notice-detail-nav-icon"
                      />
                      <span>{this.state.prevItem.title}</span>
                    </div>
                  ) : (
                    <div className="non-data">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className="notice-detail-nav-icon"
                      >
                        <path
                          d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"
                          fill="#9e9e9e"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                      <span>다음 글이 없습니다</span>
                    </div>
                  )}
                  {this.state.nextItem !== null || undefined ? (
                    <div onClick={this.nextDetail}>
                      <img
                        alt=""
                        src={prev}
                        className="notice-detail-nav-icon"
                      />
                      <span>{this.state.nextItem.title}</span>
                    </div>
                  ) : (
                    <div className="non-data">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                          fill="#9e9e9e"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                      <span>이전 글이 없습니다</span>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <button className="notice-detail-index" onClick={this.moveList}>
                LIST
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Contact_Notice_detail);
