const validate = (value, rules) => {
  let isValid = true;

  for (let rule in rules) {
    switch (rule) {
      case 'minLength':
        isValid = isValid && minLengthValidator(value, rules[rule]);
        break;
      case 'maxLength':
        isValid = isValid && maxLengthValidator(value, rules[rule]);
        break;
      case 'isRequired':
        isValid = isValid && requiredValidator(value);
        break;
      case 'isEmail':
        isValid = isValid && emailValidator(value);
        break;
      case 'isName':
        isValid = isValid && nameValidator(value);
        break;
      case 'isPassowrd':
        isValid = isValid && passwordValidator(value);
        break;

      default:
        isValid = true;
    }
  }

  return isValid;
};

/**
 * minLength Val
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => {
  return value.length >= minLength;
};

const maxLengthValidator = (value, maxLengt) => {
  return value.length <= maxLengt;
};
//
const feildLength = (value, maxLengt) => {
  if (value.length > maxLengt) {
    return value.slice(0, maxLengt);
  }
};

/**
 * Check to confirm that feild is required
 *
 * @param  value
 * @return
 */
const requiredValidator = value => {
  return value.trim() !== '';
};

/**
 * Email validation
 *
 * @param value
 * @return
 */
const emailValidator = value => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
  // const ALLOW_PATTERN_EMAIL = '^[a-zA-Z0-9]+@[a-zA-Z0-9]+.[A-Za-z]+$';
  // return ALLOW_PATTERN_EMAIL.test(String(value).toLowerCase());
};
/**
 * Password validation
 *
 * @param value
 * @return
 */
const passwordValidator = value => {
  const ALLOW_PATTERN_PASSWORD =
    '(?=.*\\d{1,})(?=.*[~`!@#$%\\^&*()-+=]{0,})(?=.*[a-zA-Z]{1,}).{8,}$';
  return new RegExp(ALLOW_PATTERN_PASSWORD).test(value);
};

const nameValidator = value => {
  const ALLOW_PATTERN_NAME = '^[가-힣a-zA-Z0-9]+$';
  return new RegExp(ALLOW_PATTERN_NAME).test(value);
};

export default validate;
