import React from 'react';
import axios from 'axios';

import * as helper from 'lib/helper';
import * as config from 'config';

export default class PotfolioList extends React.Component {
  state = {
    data: null
  };

  async initialize() {
    try {
      const response = await axios.get(`${config.API_BOARD}?type=PORTFOLIO&_page=1&_limit=30`);
      this.setState({
        data: response.data
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.initialize();
  }

  handleDelete = e => {
    const headers = {
      Authorization:
        'Bearer ' +
        localStorage.getItem('token')
    };
    const { id } = e.currentTarget;
    try {
      const response = axios
        .delete(`${config.API_BOARD}/${id}`, { headers })
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            let data = [...this.state.data];
            data = data.filter(r => r.board_id != parseInt(id, 10));
            this.setState({
              data
            });
          }
        })
        .catch(response => {
          console.error(response);
        });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const data = this.state.data;
    return (
      <div className="dashboard__content">
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Image</th>
              <th scope="col">URL</th>
              <th scope="col">Date</th>
              <th scope="col">Stat</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map(r => {
                console.log(r);
                return (
                  <tr key={r.board_id}>
                    <th scope="row">{r.board_id}</th>
                    <td>
                      <img className="x370y300" src={r.image}/>
                    </td>
                    <td>{r.title}</td>
                    <td>{helper.getyyyyMMdd(r.board_created)}</td>
                    <td>
                      <button
                        id={r.board_id}
                        type="button"
                        className="btn btn-danger"
                        onClick={this.handleDelete}
                      >
                        DEL
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}
