export default {
  en: {
    hello: 'Hello',
    home_intro:
      'is one of Korea’s leading multi-asset alternative investment firms. Our global team aligns our interests with those of our investors and partners for lasting impact.',
    company_profile1: `Truben Investment seeks to acquire industry-leading companies with sustainable competitive advantages and strong cash flows and earnings. We also work closely with the management team of companies invested to find new opportunities for growth.
      We endeavor to grow and create value in the companies by developing strategy, optimizing capital structure, adopting global best practices in operations, controls, and corporate governance and pursuing strategic growth opportunities.`,

    company_profile2: `The name ‘Truben’ stands for ‘Trust and Benefit’ which implies that our visions are to build up our client relationships based on trust and strive for mutual benefit. This is a commitment to delivering our values and responsibilities to our clients, partners and ourselves.`,

    company_history1: `Truben Investment is one of Korea’s leading private equity firms founded in 2012. We focus on investment opportunities in a broad range of industries in Korea, seeking control or significant minority stake in a target company. We rely on our network and insight in identifying and sourcing investment opportunities and in enhancing the value of our investments. Our team possesses a broad array of experiences and expertise. Our significant presence and commitment in Korea allow us to identify and source proprietary investment opportunities and to actively manage our portfolio companies.`,
    company_history2: `Our goal is to help companies create value for all of its stakeholders. We bring our investment and management expertise as well as financial support when we commit to an investment. We are a long-term investor, actively working with the management teams to build successful businesses rather than focusing on short-term gains.`,
    company_history_may: `Investment in a first bio-venture pharmaceutical company in Korea.`,
    company_history_december: `Closing fund at US $30 mill with the largest company in Taiwan for Information and Communications Technologies(ICT) purposes.`,
    company_history_november: `Advising for US $135mil bond investment.`,
    company_history_april: `Closing fund at US $43 mill for sports betting business in Korea(“Sports toto”)
    (The volume of Sports toto issued in every year is about US $4.3 billion.).`,
    company_history_july: `First flagship fund at US $100 million  for corporate financial stability purposes.`,
    company_history_january: `Truben Investment commences business.`,
    company_businesses1: 'Private Equity',
    company_private_equity: `Truben’s Private Equity invests in and partners with industry-leading companies poised for significant improvement or growth. We look to partner closely and cooperatively with management teams and entrepreneurs that have track records of successful experience in each field.

    We believe that the combination of our industry knowledge, investment experience, and operational expertise provides us with an edge in identifying and creating value in investment opportunities. Our strategy is not only to work as partners with the management of our portfolio companies, but also to assist in the operations of our businesses and leverage all of the resources and our network.

    Whether it's identifying potential investments, growing our portfolio companies or realizing their value at the right time, our strategy is geared to releasing potential. The deep understanding of the sectors in which it invests has been at the core of our strategy, giving it early access to investment opportunities and an ability to quickly add value to portfolio companies.`,
    company_businesses2: `Infrastructure`,
    company_infrastructure: `The scale of global infrastructure investment demand is enormous, but initiatives in these areas have become more difficult due to a reduction in traditional public funding and weakened economic conditions in many regions of the world. These resource issues have created a heightened need for experienced private capital.

    While we invest in a broad range of industries, Truben Investment has experience and a strong investment record in the SOC, energy & resources, financial services, heavy industrials, consumer and business services industries. Given our strength particularly in SOC and energy & resources and financial services, we selectively explore investment opportunities in these sectors outside of South Korea.

    We have a deep understanding and broad experience in complex and regulated infra-business areas and have played a key role in the collaboration with various stakeholders. We are committed to working with policymakers and key voters to develop measures that will attract capital to the infrastructure assets and help build and rebuild infrastructure around the world.`,

    management_pos_ceo: 'CEO',
    // management_name_ceo: 'Bonjin Koo',
    management_name_ceo: 'Song Jae Cho',
    management_desc_ceo: `Mr. Song, Jae-cho had been serving as a prominent journalist for 35
years who has worked for the Korea Economic Daily and Korea
Economic TV.

After joining the Korea Economic Daily in 1984, he had successfully
filled various posts such as deputy general manager of the department
of economy session, general manager of the SMEs and venture session,
general manager of Finance and Securities and then as the head of the
Korea Economic TV&#39;s reporting head, executive director, CEO and
president.

Under his management, Korea Economic TV was the first Korean
broadcaster to build and operate a real-time broadcasting system with
Chinese economy TV and, and was also evaluated to have led the
innovation of the broadcasting industry by introducing mobile pay-TV.

Mr. Song has joined Truben Investment in May 2020 as the new CEO.
Through this appointment, we plan to focus on new lighting and
diversifying the business area of Truben Investment from a new
perspective.

He has graduated from Hanyang University, Department of Journalism
and Broadcasting, and completed the Graduate School of Michigan
State University (MSU).`,
    management_pos_mp: `Executive Vice
President`,
    management_name_mp: 'Song Ho Hahn',
    management_desc_mp: `Song Ho Hahn is Managing Partner of Truben Investment and President of Azworth Trust; a specialized M&A advisory firm.

    Prior to joining Truben, Mr. Hahn had worked for Korea Development Bank (KDB), Korea Investment & Securities Co., Ltd., and Inchon Oil Refinery Co. Ltd. During his 30 years career at KDB, Mr. Hahn had led several departments to invest and finance in large-scale infrastructure projects such as Incheon International Airport and Cheonahn/Nonsan Highways, and Hyundai and GS power plants. In January 1999, Mr. Hahn was appointed as Director and General Manager of KDB’s New York Branch and was responsible for running $300million foreign currency fund.

    Mr. Hahn graduated with a BA in Economics from Yonsei University.`,
    management_pos_evp: `Vice President`,
    management_name_evp: 'Young Gon YOO',
    management_desc_evp: `Mr. Yoo had worked for Keangnam Enterprises before joining Truben, and served as Executive Vice President of energy business department from 2006 to 2013.

    Prior to joining Truben, Mr. Yoo had worked for Korea Electric Power Corporation (KEPCO) and Korea District Heating Corp. During 25 years of his career in the energy-related industry, Mr. Yoo was mainly involved in the project to acquire Ilsan combined heat and power plant (KEPCO) and standardizing the period for scheduled maintenance of combined cycle power plants (KEPCO). He also played a vigorous role in getting Korea District Heating Corp. to advance into the electricity market and obtaining permission for the construction of mid-large scaled combined heat and power plants in Paju and Hwaseong in Korea. Serving as an energy business executive, Mr. Yoo also took control of the resource development and energy department in Keangnam Enterprises.

    Mr. Yoo holds a BA in Mechanical Engineering from Hanyang University and an MA in Business Administration from Hanyang University Business School. He also has a Ph.D in Economics from Korea Polytechnic University. He was granted a ministerial citation from the Ministry of Commerce, Industry and Energy in 2003.`,
    management_pos_director: `Managing
    Director`,
    management_name_director: 'Seonghoon Kim',
    management_desc_director: `Seonghoon Kim is Manging Director of Truben Investment. Prior to joining Truben, Mr. Kim worked at Financial Advisory Service team of Samil PricewaterhouseCoopers and won 2014 Chairman’s award of Samil PwC. Mr. Kim is a Certified Public Accountant. Mr. Kim graduated with a B.A. in Business Administration from Seoul National University.`,

    management_pos_advisor: `Advisor`,
    management_name_advisor: 'Sang June Nam',
    management_desc_advisor: `Mr. Nam was the senior manager of the Korean conglomerate's division at Citi bank Korea through LG Merchant Bank, KTB Networks, Bank of Boston Seoul branch, DBS Bank Seoul Branch, Standard Chartered Bank Korea. And he has abundant financial experience in M&As, LBO Loans, global bonds, ship/aircraft finance, and structured loans etc.
    Mr. Nam graduated with a B.A. in International Economics from Seoul National University(1997).`,

    /** */
    strategy_overview: 'Strategy Overview',
    strategy_opportunities: `Finding Opportunities through Management Team`,
    strategy_investing: `Investing across a wide range of Industries`,
    strategy_overview_desc: `As the industry grows increasingly complex, teamwork and information sharing across strategies have never been so important. We believe collaboration with entire stakeholders is key to ensuring long-term, sustainable investment success, and we embrace this across every area of our business.`,
    strategy_opportunities_desc: `We set high personal and company standards, and then consistently try to exceed them. We attract self-motivated, highly capable, results-oriented people and invest heavily in their development. Leveraging a deep network of relationships that management team throughout industries, Truben seeks to cultivate proprietary or specialized investment opportunities.`,
    strategy_investing_desc: `We invest in companies that create stable cash flow and that are relatively inelastic to macro changes across a broad spectrum of industries. Truben’s ideal target company has a leading position in its market, a competitively advantaged business model, a strong management team, proprietary core technologies, sound business processes, and the potential for innovational value creation.`,
    strategy_approach_title: `Our Approach`,
    strategy_approach: `As investors with an industrialist vision, we aim to generate attractive investment returns by employing high-quality people, pursuing the highest standards of excellence, and aligning our interests with those of our investment partners.`,
    strategy_approach_expertise: 'Optimized Expertise',
    strategy_approach_engagement: 'Stakeholder engagement',
    strategy_approach_investment: 'Fundamentally Sound Investment',
    strategy_approach_returns: 'Sustainable Returns',
    strategy_approach_expertise_desc:
      'Our investment process is optimized by the best partnerships in the industry from the planning stage to operation and maintenance.',
    strategy_approach_engagement_desc:
      'We regularly engage with a range of stakeholders, including individuals, investors, and portfolio company management and employees who are affected by our business decisions and can contribute to our success.',
    strategy_approach_investment_desc:
      'We are founded based upon a belief that patient capital and fundamentally sound investment mandate are competitive advantages in the pursuit of superior, long-term risk-adjusted returns.',
    strategy_approach_returns_desc:
      'We invest in products that we believe can deliver long term sustainable returns that are predictable with a great level of certainty.',
    portfolio__desc:
      'At Truben Investment, we invest in companies that represent an array of industries and stages, including buyout, growth equity, and mezzanine. Truben currently has a wide range of portfolio across bio-venture companies, sports betting businesses, medical equipment companies, deep learning solutions, solar power inverters, online game developers, social recruiting platforms as well as bio pharmaceuticals.',
    contact_address: `45F, Three IFC, 10, Gukjegeumyoung-ro,
Yeongdeungpo-gu, Seoul, 07326, Korea`,
    contact_board_title: `Notice`,
  },

  /**
   * ko
   */
  ko: {
    hello: '안녕하세요',
    home_intro:
      '트루벤인베스트먼트는 국내 대표적인 사모펀드 투자회사입니다. 트루벤은 지속적인 성장을 위해 투자자 및 파트너의 이익과 회사의 이익을 일치시키고 있습니다.',
    company_profile1: `트루벤은 지속 가능한 경쟁우위 요소와 안정적인 현금흐름, 그리고  수익성을 보유한 선도기업군 발굴에 힘쓰고 있으며, 피투자기업의 경영진과의 긴밀한 협력을 통해 새로운 성장 기회를 모색하고 있습니다.
    또한 전략 개발, 자본 구조의 최적화, 운영 및 기업지배구조의 선진화, 전략적 성장 기회 추구 등을 통해 피투자기업의 가치 창출을 위해 힘쓰고 있습니다.`,
    company_profile2: `회사명인 Truben은 고객과의 신뢰(Trust)를 바탕으로 서로 상생하고 혜택(Benefit)을 공유한다는 의미를 내포하고 있습니다. 이는 고객과의 약속, 고객에 대한 책임을 무한히 가져가겠다는 스스로에 대한 다짐이기도 합니다.`,
    company_history1: `트루벤 인베스트먼트는 2012년 설립된 국내 대표적인 사모펀드 투자회사입니다. 트루벤은 국내 다양한 산업에서의 바이아웃 및 적극적인 경영참여를 전제로 한 주요 지분투자 기회에 집중하고 있으며, 투자 기회 발굴 및 투자대상 기업의 가치 제고를 위해 국내 시장에 대한 깊은 이해와 넓은 인적 네트워크를 적극 활용하고 있습니다. 트루벤은 다양한 분야에 대한 풍부한 경험과 전문성을 기반으로 신규 투자 기회를 발굴하고 있으며 동시에 피투자 기업에 대한 관리도 적극적으로 수행하고 있습니다. `,
    company_history2: `트루벤은 피투자 기업의 가치 제고를 통한 장기적인 시각에서의 투자수익률 극대화를 목표로 하고 있습니다. 이를 위해 피투자 기업 경영진과의 협력을 통한 장기적 기업가치 제고를 최우선의 과제로 설정하고, 재무, 관리, 영업 등 경영의 전 분야에 걸친 지원을 아끼지 않고 있습니다.`,
    company_history_may: `국내 바이오벤처 1호 신약개발 회사 투자`,
    company_history_december: `대만 최대 기업과 3,000만 달러 규모의 정보통신기술(ICT) 펀드 설립`,
    company_history_november: `1억3,500만 달러 규모 채권 투자 자문`,
    company_history_april: `체육진흥투표권 사업을 위해 500억원 규모의 펀드를 결성하고,
    스포츠토토를 운영하는 수탁자로 선정 (매년 5조원에 달하는 스포츠복권을 발행)`,
    company_history_july: `1호 펀드 1,010억원 규모의 재무안정펀드 등록`,
    company_history_january: `트루벤인베스트먼트 설립`,
    company_businesses1: `사모펀드`,
    company_private_equity: `트루벤 사모펀드는 높은 실적 개선이나 성장이 가능한 업계 최고의 기업들에 투자하고 있습니다. 우리는 피투자 기업들과 공고한 협력관계를 구축하고 있으며, 각 분야에서 성공 경험을 보유한 경영진 및 기업가들과 긴밀한 협력 관계를 맺고 있습니다.

    트루벤이 보유한 산업에 대한 풍부한 이해, 투자 경험, 운영 지식은 투자 대상을 선택하고 가치를 창출함에 있어 남다른 경쟁력을 제공하고 있습니다. 피투자 기업에 단순히 경영진 및 파트너로서 참여하는 것을 넘어, 피투자 기업의 운영 지원을 위해 모든 네트워크와 자원을 활용하고자 하는 것이 트루벤의 전략입니다.

    잠재적인 투자 대상을 발굴하고, 피투자 기업을 성장시키고, 적절한 기회에 가치를 실현함에 있어, 트루벤은 투자 대상의 숨겨진 잠재력을 드러내는 데 집중하고 있습니다. 투자 대상에 대한 깊이 있는 이해는 투자 기회에 대한 한발 빠른 접근과 피투자 기업에 대한 가치 상승을 가능케하는  트루벤 전략의 핵심입니다.`,
    company_businesses2: `인프라 투자`,
    company_infrastructure: `글로벌 인프라 투자 수요는 막대하나, 인프라 분야에 대한 투자는 공적 자금의 감소와 세계 경제 여건 악화로 인해 점차 어려워지면서, 경험이 풍부한 민간 자본의 필요성이 더욱 높아지고 있습니다.

    트루벤은 에너지자원, 중공업, 서비스업, SOC 사업 등 다양한 산업부문의 투자를 통해 강한 전문성을 갖추고 있으며, 이러한 SOC, 에너지 자원, 자금조달 부분의 전문성을 바탕으로 해외에서도 투자기회를 선별적으로 모색하고 있습니다.

    트루벤은 복잡하고 규제가 강한 인프라 분야에 대한 깊은 이해와 다양한 경험을 갖고 있으며, 그동안 여러 이해 관계자들과의 협력관계 구축에 있어  핵심적인 역할을 담당해 왔습니다. 트루벤은 정책 입안자 및 주요 이해관계자들과의 협력을 통해 인프라에 자본을 유치하고 세계 각지에 인프라 구축과 재건에 이바지할 수 있는 방안을 모색하고 있습니다.`,
    management_pos_ceo: '대표이사',
    management_name_ceo: '송재조',
    management_desc_ceo: `송재조 대표는 한국경제신문, 한국경제TV에서 35년 근무한 언론인 출신 입니다.
1984년 한국경제신문에 입사하여 경제부 차장, 벤처중기부 부장, 금융부 부장, 증권부 부장,
한국경제TV의 보도본부장, 전무, 대표이사, 사장을 역임하였습니다.

한국 방송사로선 처음으로 중국경제TV와 실시간 방송 시스템을 구축, 가동 중이며, 모바일 유료방송을
도입하는 등 방송계 혁신을 이끌었다는 평가도 받았습니다.

2020년 5월 트루벤인베스트먼트 대표이사로 새로운 출발을 하였으며, 당사의 사업영역을 새로운
시각에서 재조명, 다각화 하는데 힘쓸 생각입니다.

송재조 대표는 한양대학교 신문방송학과를 졸업하고, 미시간주립대학교(MSU) 국제대학원을
수료하였습니다.`,
    management_pos_mp: `수석 부사장`,
    management_name_mp: '한송호',
    management_desc_mp: `한송호 수석부사장은 현재 트루벤 인베스트먼트의 협력사인 애즈워드트러스트의 대표이사를 겸직하고 있습니다.

    애즈워드트러스트에서 진로, 대우건설, 동남아해운 등 M&A 업무를 수행하였고, 인천정유 및 프로칩스의 법정 관리인으로써 M&A를 통하여 성공적으로 기업 정상화를 실현한 경력을 보유하고 있습니다. 한국산업은행 SOC 사업부(현 PF사업부) 부장으로 재직하면서, 인천공항 고속도로 민자사업, 천안/논산 고속도로 민자사업, KT, SKT, LG Mobile Phone 사업, 현대, LG(현 GS) 민간발전 사업을 주도하였으며, New York 지점장, BOK 외화자금 US$300백만 운영, 양키본드,사무라이 본드, 신디케이션론 등 외화자금 조달, 선물, 옵션, 스왑 등 국제 금융 업무 등을 수행하였습니다.

    한송호 수석부사장은 한국산업은행에서 30년간 재직하였으며, 연세대학교 경제학과를 졸업하였습니다.`,
    management_pos_evp: `부사장`,
    management_name_evp: '유영곤',
    management_desc_evp: `유영곤 부사장은 트루벤 인베스트먼트 재직 전 경남기업 에너지사업본부의 전무로 근무하였습니다.

    한국전력공사, 한국지역난방공사 등 에너지사업부문에서 20여 년 동안 근무하면서, 한전 일산 열병합발전소 인수, 한전 복합화력발전소 표준 계획정비 주기 표준화 시행, 한국지역난방공사 열병합발전소 전력시장 진입추진, 파주 및 화성 중대형 열병합발전소 건설 사업성 허가 취득 등의 업무 경험을 보유하고 있습니다. 또한 경남기업에서 에너지사업담당임원으로 역임하면서 자원개발 업무 및 에너지정책을 총괄 추진하였습니다.

    유영곤 부사장은 한양대학교 기계공학과를 졸업하였으며, 한양대학교 경영학 석사 및 한국산업기술대학 에너지정책대학원에서 경제학 박사 학위를 취득하였습니다. 산업자원부로부터 산업자원부장관상을 표창한 바 있습니다.`,
    management_pos_director: `상무이사`,
    management_name_director: '김성훈',
    management_desc_director: `김성훈 상무는 트루벤인베스먼트 재직 전, 삼일회계법인의 재무 자문팀에서 근무했으며 2014 년 삼일회계법인 대표상을 받은 바 있습니다.
    김성훈 상무는 공인 회계사이며, 서울대학교 경영학과를 졸업하였습니다.`,

    management_pos_advisor: `자문위원`,
    management_name_advisor: '남상준',
    management_desc_advisor: `LG종금, KTB네트웍스, Bank of Boston 서울지점, 싱가포르 DBS Bank 서울지점, 한국스탠다드챠타드은행을 거쳐 한국씨티은행에서 한국 대기업 담당 부문장을 역임하였으며, M&A, 인수금융, 해외채권 발행, 선박/항공 금융 등에서 풍부한 경험을 보유하고 있습니다.
    남상준 자문위원은 서울대학교 국제경제학과를 졸업(1997년)하였습니다.`,

    /** */
    strategy_overview: '전략개요',
    strategy_opportunities: '구성원을 통한 투자 기회 발굴',
    strategy_investing: '광범위한 산업 분야에 투자',
    strategy_overview_desc: `산업의 복잡성이 높아지면서, 투자 전략 전반에 걸쳐 팀워크와 정보 공유가 그 어느 때보다 중요해지고 있습니다. 트루벤은 장기적이고 지속적인 투자의 성공을 보장하는 데 있어 여러 이해관계자와의 협력이 무엇보다 중요하다고 판단하고, 모든 분야에서 협력을 바탕으로 사업을 진행하고 있습니다.`,
    strategy_opportunities_desc: `트루벤은 구성원들과 기업경영에 있어 높은 수준의 기준을 세우고, 이를 뛰어넘기 위해 지속적으로 노력하고 있습니다. 트루벤은 자기 동기부여가 강하고, 능력이 뛰어나며, 결과 창출에 대한 의지가 높은 인재들을 모아 그들의 발전을 적극 지원하고 있으며, 구성원들이 보유한 산업 전반에 걸친 심도 깊은 네트워크를 통해 독점적이고 전문적인 투자 기회를 발굴하고 있습니다.`,
    strategy_investing_desc: `트루벤은 안정적인 현금흐름을 창출하면서도 거시적 변화에는 상대적으로 비탄력적인 기업들에 대해 다양한 산업에 걸쳐 투자하고 있습니다. 우리가 목표로 하는 이상적인 투자대상은 시장 선도적인 지위, 경쟁력 있는 사업 모델, 강력한 경영진, 독점적 핵심 기술, 건전한 비즈니스 프로세스 및 혁신적인 가치 창출을 위한 잠재력을 보유하고 있는 기업입니다.`,
    strategy_approach_title: `투자접근법`,
    strategy_approach: `트루벤은 기업가 정신에 입각한 투자자로서, 우수한 인력을 고용하고, 최고 수준의 탁월함을 추구하며, 회사의 이익을 투자 파트너들의 이익과 일치시킴으로써 매력적인 투자 수익을 이끌어내는 것을 목표로 합니다.`,
    strategy_approach_expertise: '최적화된 전문성',
    strategy_approach_engagement: '이해 관계자 참여',
    strategy_approach_investment: '안전자산에 대한 투자',
    strategy_approach_returns: '지속 가능한 수익',
    strategy_approach_expertise_desc: `우리의 투자 프로세스는 산업 최고의 파트너들에 의해 계획 단계부터 운영 및 유지 단계까지 전 과정에 걸쳐 최적화 되어있습니다.`,
    strategy_approach_engagement_desc: `우리는 사업적 판단과 성과에 있어 서로 영향을 미칠 수 있는 개인, 투자자 및 피투자 기업의 임직원 등 이해관계자들과  끊임없이 소통합니다.`,
    strategy_approach_investment_desc: `우리는 장기적인 관점에서 사업전망이 확실한 안전자산 투자를 통해 경쟁우위를 창출한다는 신념을 근간으로 하고 있으며, 이를 통해 성공적이고 장기적으로 안정적인 사업 수익을 추구합니다.`,
    strategy_approach_returns_desc:
      '우리는 지속적인 수익 창출을 위하여 예측가능하고(predictable) 확실성이 높다고(high certainty) 판단되는 상품과 프로젝트에 투자합니다.',
    portfolio__desc:
      '트루벤인베스트먼트는 바이아웃, 성장자본, 메자닌 등을 통해 각 산업 및 성장 단계를 대표하는 기업들에 투자하고 있습니다. 당사는 현재 혁신신약  개발 바이오벤처, 스포츠 베팅 기업, 의료 장비 업체, 딥러닝 솔루션 개발사, 태양광 발전 장치 제조사, 온라인 게임 개발업체, 인재 채용 플랫폼 및 바이오 제약사 등 다양한 분야에 대한 투자 포트폴리오를 보유하고 있습니다.',
    contact_address: `서울특별시 영등포구 국제금융로 10
Three IFC (쓰리 아이에프씨) 45층`,
    contact_board_title: `공고`,
  },
};
