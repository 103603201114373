import React from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import Dashboard from "./Dashboard";
import PortfolioList from "./PortfolioList";
import PortfolioAdd from "./PortfolioAdd";
import Notice from "./Notice/Notice";
import MakeNotice from "./Notice/MakeNotice";
import ModifyNotice from "./Notice/modifyNotice";

export default class Admin extends React.Component {
  handleLogout = e => {
    document.getElementById("navbarToggle").click();
    localStorage.removeItem("token");
    this.props.history.push("/admin/login");
  };

  handleToggle = e => {
    document.getElementById("navbarToggle").click();
  };

  render() {
    return (
      <React.Fragment>
        <div className="pos-f-t">
          <div className="collapse" id="navbarToggleExternalContent">
            <div className="bg-dark p-4">
              <h1 className="text-white">
                <Link
                  className="logo__warp"
                  to="/admin"
                  onClick={this.handleToggle}
                >
                  Truben
                </Link>
              </h1>
              <span className="text-muted link">
                <Link
                  className="logo__warp"
                  to="/admin/list"
                  onClick={this.handleToggle}
                >
                  포트폴리오 리스트
                </Link>
              </span>
              <hr />
              <span className="text-muted link">
                <Link
                  className="logo__warp"
                  to="/admin/add"
                  onClick={this.handleToggle}
                >
                  포트폴리오 추가하기
                </Link>
              </span>
              <hr />
              <span className="text-muted link">
                <Link
                  className="logo__warp"
                  to="/admin/notice"
                  onClick={this.handleToggle}
                >
                  게시글 관리
                </Link>
              </span>
              {localStorage.getItem("token") ? (
                <React.Fragment>
                  <hr />
                  <span className="text-muted link" onClick={this.handleLogout}>
                    LogOut
                  </span>
                </React.Fragment>
              ) : (
                undefined
              )}
            </div>
          </div>
          <nav className="navbar navbar-dark bg-dark">
            <button
              id="navbarToggle"
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
          </nav>
        </div>
        <div className="container">
          <div className="dashboard__content">
            <Switch>
              <Route path="/admin/login" component={Login} />
              <PrivateRoute path="/admin/dashboard" component={Dashboard} />
              <PrivateRoute path="/admin/add" component={PortfolioAdd} />
              <PrivateRoute path="/admin/list" component={PortfolioList} />
              <PrivateRoute path="/admin/notice" component={Notice} />
              <PrivateRoute path="/admin/make" component={MakeNotice} />
              <PrivateRoute path="/admin/:id" component={ModifyNotice} />
              <Route component={Login} />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
