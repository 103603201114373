import React from 'react';

const EmaiInput = props => {
  let formControl = 'form-control';
  let formGroup = 'form-group';
  let customClass = '';
  if (props['data-custom-class']) {
    customClass = props['data-custom-class'];
  }
  if (props.touched && !props.valid) {
    formControl = 'form-control control-error';
    formGroup = 'form-group has-error has-danger';
  }
  return (
    <div className={formGroup}>
      <label htmlFor={props.name} className={customClass}>
        {props.name}
      </label>
      <input type="email" className={formControl} {...props} />
      <div className="help-block with-errors">
        {props.touched && !props.valid ? (
          <ul className="list-unstyled">
            <li>{props['data-error']}</li>
          </ul>
        ) : (
          undefined
        )}
      </div>
    </div>
  );
};

export default EmaiInput;
