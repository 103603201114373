import React, { Component } from 'react';
// import logo from './logo.svg';
import { Switch, Route } from 'react-router-dom';
import Main from './Main';
import Admin from './admin';
import './App.scss';
import * as helper from 'lib/helper';

class App extends Component {
  constructor(props) {
    super(props);
    const isIE = helper.detectIE();
    console.log('isIE', isIE);
  }
  render() {
    return (
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/" component={Main} />
      </Switch>
    );
  }
}

export default App;
