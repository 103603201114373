import React, { Component } from "react";
import Axios from "axios";
import * as config from "config";
import indent from "assets/icon/outline-format_indent_increase-24px.svg";
import outdent from "assets/icon/outline-format_indent_decrease-24px.svg";
import left from "assets/icon/outline-format_align_left-24px.svg";
import center from "assets/icon/outline-format_align_center-24px.svg";
import right from "assets/icon/outline-format_align_right-24px.svg";
import bold from "assets/icon/outline-format_bold-24px.svg";
import photo from "assets/icon/outline-insert_photo-24px.svg";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

export class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.data ? this.props.data : "<p></p>",
    };
    this.ok = this.ok.bind(this);
  }

  modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        // handlers object will be merged with default handlers object
        image: this.imageHandler,
      },
    },
  };
  // formats = ["bold", "indent", "image", "align"];

  imageHandler() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    const range = this.quill.getSelection();
    input.click();
    // function controlselectHandler(evt) {
    //   evt.preventDefault();
    // }
    // document.body.addEventListener("mscontrolselect", controlselectHandler);
    if (input.files[0]) {
      const file = input.files[0];
      const Form = new FormData();
      Form.append("image", file);
      Axios.post(`${config.API_IMAGE_MAKE}`, Form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then(el => {
        const link = `${config.API_IMAGE}${el.data.savename}`;
        this.quill.insertEmbed(range.index, "image", link);
      });
    }
    input.onchange = function() {
      const file = input.files[0];
      const Form = new FormData();
      Form.append("image", file);
      Axios.post(`${config.API_IMAGE_MAKE}`, Form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(el => {
          const link = `${config.API_IMAGE}${el.data.savename}`;
          this.quill.insertEmbed(range.index, "image", link);
        })
        .catch(err => {
          localStorage.removeItem("token");
          window.location.reload();
        });
    }.bind(this); // react thing
  }

  ok = text => {
    this.setState({
      text,
    });
    this.props.getContent(text);
  };

  render() {
    return (
      <div>
        <CustomToolbar />
        <ReactQuill
          value={this.state.text}
          onChange={this.ok}
          style={{ height: "500px", marginBottom: "3rem" }}
          modules={this.modules}
          formats={this.formats}
        />
      </div>
    );
  }
}

export default Editor;

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold" />
    <button className="ql-indent" value="+1" />
    <button className="ql-indent" value="-1" />
    <button className="ql-align" />
    <button className="ql-align" value="center" />
    <button className="ql-align" value="right" />
    <button className="ql-image" />
  </div>
);
