import './FileItemList.scss';
import React from 'react';
import FileItem from './FileItem';

export default class FileItemList extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  getKey = () => {
    return new Date().getUTCMilliseconds();
  };

  handleFileAttach = e => {
    if (e !== undefined && e.preventDefault) e.preventDefault();
    if (this.props.file.length >= this.props.maxLength) {
      return alert('데이터 로드 불가');
    }
    const [file] = e.target.files;
    if (file && file.size && file.size > 5242880) {
      return alert('5MB 이하만 업로드가 가능합니다.');
    }
    const attach = { file, ...{ file_id: this.getKey() } };
    const files = [...this.props.file, attach];
    this.props.onFileChange(files);
  };

  handleFileDelete = idx => {
    let files = this.props.file;
    // files.splice(idx, 1);
    files = files.filter(r => r.file_id !== idx);
    this.props.onFileChange(files);
  };

  handleDeletedFileRow = deletedFileId => {
    this.props.handleDeletedFileRow(deletedFileId);
  };

  render() {
    return (
      <div className="file_add">
        <span className="label_txt">{this.props.label}</span>
        <div className="file_scroll">
          <div className="form-group file_form">
            <ul className="file_list">
              {this.props.file &&
                this.props.file.map(r => {
                  return (
                    <FileItem
                      key={r.file_id}
                      id={r.file_id}
                      file={r.file ? r.file : r}
                      handleFileDelete={this.handleFileDelete}
                      handleDeletedFileRow={this.handleDeletedFileRow}
                      // handleFileDelete={() => this.handleFileDelete()}
                    />
                  );
                })}
            </ul>
            <label
              htmlFor={this.props.label}
              className={this.props.file.length >= this.props.maxLength ? 'hidden' : 'btn_file'}
            />
            <input
              disabled={this.props.file.length >= this.props.maxLength}
              id={this.props.label}
              type="file"
              name="uploadBtn"
              className="uploadBtn"
              onChange={this.handleFileAttach}
              accept={this.props.accept}
            />
          </div>
          <span className="file_add_txt">{this.props.info}</span>
        </div>
      </div>
    );
  }
}
