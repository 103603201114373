import React from 'react';
// import { Helmet } from 'react-helmet';
// import './style.scss';
import components from 'components/Index';
import { injectIntl } from 'react-intl';

class Management extends React.Component {
  render() {
    const { intl } = this.props;
    return (
      <div id='management' className='management'>
        <components.Masthead
          addClass={'management'}
          title={'CEO'} /*title={'Management'}*/
        />
        <ul className='profile__list'>
          <li>
            <div className='content management'>
              <div className='profile'>
                <div className='profile__title'>
                  <div className='position'>
                    {intl.formatMessage({ id: 'management_pos_ceo' })}
                  </div>
                  <div className='name'>
                    {intl.formatMessage({ id: 'management_name_ceo' })}
                  </div>
                </div>
                <div className='desc'>
                  {intl.formatMessage({ id: 'management_desc_ceo' })}
                </div>
              </div>
            </div>
          </li>

          {/*
          <li>
            <div className='content management'>
              <div className='profile'>
                <div className='profile__title'>
                  <div className='position'>
                    {intl.formatMessage({ id: 'management_pos_mp' })}
                  </div>
                  <div className='name'>
                    {intl.formatMessage({ id: 'management_name_mp' })}
                  </div>
                </div>
                <div className='desc'>
                  {intl.formatMessage({ id: 'management_desc_mp' })}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className='content management'>
              <div className='profile'>
                <div className='profile__title'>
                  <div className='position'>
                    {intl.formatMessage({ id: 'management_pos_evp' })}
                  </div>
                  <div className='name'>
                    {intl.formatMessage({ id: 'management_name_evp' })}
                  </div>
                </div>
                <div className='desc'>
                  {intl.formatMessage({ id: 'management_desc_evp' })}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className='content management'>
              <div className='profile'>
                <div className='profile__title'>
                  <div className='position'>
                    {intl.formatMessage({ id: 'management_pos_director' })}
                  </div>
                  <div className='name'>
                    {intl.formatMessage({ id: 'management_name_director' })}
                  </div>
                </div>
                <div className='desc'>
                  {intl.formatMessage({ id: 'management_desc_director' })}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className='content management'>
              <div className='profile'>
                <div className='profile__title'>
                  <div className='position'>
                    {intl.formatMessage({ id: 'management_pos_advisor' })}
                  </div>
                  <div className='name'>
                    {intl.formatMessage({ id: 'management_name_advisor' })}
                  </div>
                </div>
                <div className='desc'>
                  {intl.formatMessage({ id: 'management_desc_advisor' })}
                </div>
              </div>
            </div>
          </li>
          */}
        </ul>
        {/* end */}
      </div>
    );
  }
}
export default injectIntl(Management);
