import React from 'react';
import { Link } from 'react-router-dom';

import { injectIntl } from 'react-intl';

class NavBar2 extends React.Component {
  constructor(props) {
    super(props);

    const { locale } = this.props.intl;
    this.state = {
      isOpen: false,
      locale,
    };
  }

  handleMenuOpen = e => {
    if (e.target.className === 'side__nav') {
      console.log(e.target.className);
      return;
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
    // tmp.style.transform = 'translateX(0px)';
    // const st = { transform: 'translateX(0px)' };
  };

  closeMenu = e => {
    this.setState({
      isOpen: false,
    });
  };

  handleLocale = e => {
    const locale = e.target.dataset['locale'];
    console.log(this.props);
    localStorage.setItem('lang', locale);
    window.location.reload();
  };

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <nav id='nav' className={this.state.isOpen ? 'nav open' : 'nav'}>
        <Link className='logo__warp' to='/' />
        <div className='menu__warp'>
          <button className='menu__button' onClick={this.handleMenuOpen} />
        </div>

        <div className='sidenav-overlay' onClick={this.handleMenuOpen}>
          <ul className='side__nav' ref='sidenav'>
            <div className='close__wrap'>
              <button className='close__button' />
            </div>
            <li>
              <Link to='/' onClick={this.closeMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to='/company' onClick={this.closeMenu}>
                Company
              </Link>
            </li>
            <li>
              <Link to='/management' onClick={this.closeMenu}>
                {/* Management */}
                CEO
              </Link>
            </li>
            <li>
              <Link to='/strategy' onClick={this.closeMenu}>
                Strategy
              </Link>
            </li>
            <li>
              <Link to='/portfolio' onClick={this.closeMenu}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link to='/contact' onClick={this.closeMenu}>
                Notice
              </Link>
            </li>
            <li>
              <div className='locale__wrap'>
                <span
                  data-locale='en'
                  className={this.state.locale === 'en' ? 'active' : undefined}
                  onClick={this.handleLocale}
                >
                  ENG
                </span>
                <span
                  data-locale='ko'
                  className={this.state.locale === 'ko' ? 'active' : undefined}
                  onClick={this.handleLocale}
                >
                  KOR
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
export default injectIntl(NavBar2);
