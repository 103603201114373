import React from 'react';

export default class FileItem extends React.Component {
  constructor() {
    super();
    this.state = {
      isImage: false,
      thumbnail: null,
      name: '',
      size: 0
    };
  }

  componentDidMount() {
    const { file, type } = this.props;
    // const tmp = file;
    // console.log(tmp);
    const reader = new FileReader();
    try {
      const url = reader.readAsDataURL(file);
      let isImage = false;
      if (file.type.indexOf('image') > -1) {
        isImage = true;
      }
      reader.onloadend = function(e) {
        this.setState({
          name: file.name,
          thumbnail: e.target.result,
          size: this.bytesToSize(file.size),
          isImage: isImage
        });
        // this.state.thumbnail = e.target.result;
      }.bind(this);
    } catch (err) {
      if (err.name === 'TypeError') {
        if (file.thumbnail) {
          return this.setState({
            isImage: true,
            thumbnail: file.thumbnail
          });
        } else {
          return this.setState({
            name: file.realname,
            size: undefined
          });
        }
      }
      console.error(err);
    }
  }

  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  handleFileDelete = () => {
    const { id, file, handleFileDelete, handleDeletedFileRow } = this.props;
    // console.log(id);
    if (file.file_id && !file.thumbnail) {
      handleDeletedFileRow(file.file_id);
    }
    handleFileDelete(id);
  };

  render() {
    return (
      <li className={this.state.isImage ? 'file_screen' : ''}>
        {/* <li> */}
        <button className="file_del" onClick={this.handleFileDelete}>
        </button>
        <span>
          <span className="file_area">
            {this.state.isImage && <img src={this.state.thumbnail} alt="" />}
          </span>
          <span className="file_txt">
            <strong>{this.state.name}</strong>
            {/* <strong>{this.props.file.name}</strong> */}
            <span>{this.state.size ? this.state.size : undefined}</span>
          </span>
        </span>
      </li>
    );
  }
}
