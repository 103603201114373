import Slider from "./Slider";
import Masthead from "./Masthead";
import Footer from "./Footer";
import LayerPopup from "./LayerPopup";
import Notice from "./Notice/Contact_Notice";

export default {
  Slider,
  Masthead,
  Footer,
  LayerPopup,
  Notice,
};
