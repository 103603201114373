import React from 'react';
// import { Helmet } from 'react-helmet';
// import './style.scss';
// import components from 'components/Index';
import components from 'components/Index';
// import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

// import locale from 'assets/locale';

class Home extends React.Component {
  componentDidMount() {}
  render() {
    // console.log(locale);
    const { intl } = this.props;
    return (
      <React.Fragment>
        <components.LayerPopup />
        <div>
          <div className='home'>
            <div className='title__warp'>
              <div>
                <p>Truben Investment</p>
                <span>
                  {/* <FormattedMessage id="home_intro" /> */}
                  {intl.formatMessage({ id: 'home_intro' })}
                </span>
              </div>
            </div>
            <div className='portfolio__wrap'>
              <components.Slider locale={intl.locale} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default injectIntl(Home);
