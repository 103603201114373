import React from "react";
import { Switch, Route, Link } from "react-router-dom";

export default class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <Link to="/admin/list">PORTFOLIO LIST</Link>
        <hr />
        <Link to="/admin/add">PORTFOLIO ADD</Link>
        <hr />
        <Link to="/admin/notice"> NOTICE LIST </Link>
      </div>
    );
  }
}
