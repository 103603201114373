import React from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';

import axios from 'axios';
import * as config from 'config';

import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import validate from './Validate';
import * as constants from './RegexPattern';

export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: localStorage.getItem('token') ? true : false,
      formIsValid: false,
      formControls: {
        email: {
          name: 'email',
          value: '',
          placeholder: 'Email',
          valid: false,
          touched: false,
          dataError: '잘못된 이메일 형식입니다.',
          validationRules: {
            minLength: 3,
            maxLength: 128,
            isEmail: true
          }
        },
        password: {
          name: 'password',
          value: '',
          placeholder: '영문자 및 숫자 포함 8자 이상',
          valid: false,
          touched: false,
          dataError: '8자리 이상의 영어 + 숫자 형태로 입력해주세요.',
          pattern: constants.ALLOW_PATTERN_PASSWORD,
          validationRules: {
            minLength: 8,
            maxLength: 20,
            isPassowrd: true
          }
        }
      }
    };
    console.log(this.state.isLoggedIn);
  }

  async login(formData) {
    console.log(formData);
    try {
      await axios.post(`${config.API_LOGIN}`, formData).then(response => {
        console.log(response);
        if (response.status === 200) {
          localStorage.setItem('token', response.data.access_token);
          this.setState({
            isLoggedIn: true
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  changeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedControls = {
      ...this.state.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    this.setState({
      formControls: updatedControls,
      formIsValid: formIsValid
    });
  };

  handleSubmit = e => {
    const { email, password } = this.state.formControls;
    const formData = {
      user_email: email.value,
      user_password: password.value
    };
    this.login(formData);
  };

  render() {
    if (this.state.isLoggedIn) {
      return <Redirect to="/admin/dashboard" />;
    }
    return (
      <React.Fragment>
        <div className="">
          <h1>
          <span className="badge badge-secondary">TRUBEN ADMIN</span>
          </h1>
          <form onSubmit={this.handleSubmit}>
            <EmailInput
              name={this.state.formControls.email.name}
              placeholder={this.state.formControls.email.placeholder}
              value={this.state.formControls.email.value}
              onChange={this.changeHandler}
              touched={this.state.formControls.email.touched ? 1 : 0}
              valid={this.state.formControls.email.valid ? 1 : 0}
              data-custom-class={this.state.formControls.email.dataCustomClass}
              data-error={this.state.formControls.email.dataError}
            />
            <PasswordInput
              name={this.state.formControls.password.name}
              placeholder={this.state.formControls.password.placeholder}
              value={this.state.formControls.password.value}
              onChange={this.changeHandler}
              touched={this.state.formControls.password.touched ? 1 : 0}
              valid={this.state.formControls.password.valid ? 1 : 0}
              data-custom-class={this.state.formControls.password.dataCustomClass}
              data-error={this.state.formControls.password.dataError}
            />

            <button
              type="button"
              className="btn btn-primary"
              disabled={!this.state.formIsValid}
              onClick={this.handleSubmit}
            >
              LOGIN
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
