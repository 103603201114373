import React, { Component } from "react";
// import logo from './logo.svg';
import { Switch, Route } from "react-router-dom";
import TrubenNav from "components/Navbar/NavBar";
import components from "components/Index";
import Home from "containers/Home";
import Contact from "containers/Contact";
import Compnay from "containers/Compnay";
import Management from "containers/Management";
import Strategy from "containers/Strategy";
import Portfolio from "containers/Portfolio";
import Detail from "containers/Contact_Notice_detail";

export default class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <TrubenNav />
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/company" component={Compnay} />
            <Route path="/management" component={Management} />
            <Route path="/strategy" component={Strategy} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/contact" component={Contact} />
            <Route path="/detail/:id" component={Detail} />
          </Switch>
        </div>
        <components.Footer />
      </React.Fragment>
    );
  }
}
