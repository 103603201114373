import React, { Component } from 'react';

export default class Masthead extends Component {
  render() {
    let mastheadClass = 'masthead ';
    if (this.props.addClass) {
      mastheadClass = mastheadClass + this.props.addClass;
    }
    return (
      <div className={mastheadClass}>
        <div className="masthead__title">{this.props.title}</div>
      </div>
    );
  }
}
