import React, { Component } from "react";
import Axios from "axios";
import * as config from "config";

class Notice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notice: null,
      pageCount: [],
      nowPage: 1,
    };
  }

  componentWillMount() {
    Axios.get(
      `${config.API_BOARD}?_limit=10&_page=${
        this.state.nowPage
      }&type=NOTICE&_order=board_created&_sort=DESC`
    ).then(res => {
      let pageArray = [];
      for (let i = 1; i <= Math.ceil(res.headers["x-total-count"] / 10); i++) {
        pageArray.push(i);
      }
      this.setState({
        notice: res.data,
        pageCount: pageArray,
      });
    });
  }
  componentDidMount() {
    Axios.get(
      `${config.API_BOARD}?_limit=10&_page=${
        this.state.nowPage
      }&type=NOTICE&_order=board_created&_sort=DESC`
    ).then(res => {
      let pageArray = [];
      for (let i = 1; i <= Math.ceil(res.headers["x-total-count"] / 10); i++) {
        pageArray.push(i);
      }
      this.setState({
        notice: res.data,
        pageCount: pageArray,
      });
    });
  }

  Pagination = page => {
    const id = Number(page.target.id);
    if (id !== this.state.nowPage) {
      Axios.get(`${config.API_BOARD}?_limit=10&_page=${id}&type=NOTICE`).then(
        res => {
          this.setState({
            notice: res.data,
            nowPage: id,
          });
        }
      );
    }
  };

  pageMove = () => {
    this.props.history.push("/admin/make");
    this.setState({
      notice: null,
      pageCount: [],
      nowPage: 1,
    });
  };
  modifyNotice = id => {
    this.props.history.push(`/admin/${id}`);
    this.setState({
      notice: null,
      pageCount: [],
      nowPage: 1,
    });
  };
  render() {
    const { notice, pageCount } = this.state;
    return (
      <div className="notice-board">
        <button className="new-notice-btn" onClick={this.pageMove}>
          새 글 쓰기
        </button>
        <div className="notice-dashboard">
          <div className="head">
            <span>등록일</span>
            <span>제목</span>
            <span className="none">adasd</span>
          </div>
          {notice ? (
            <div>
              {notice.length > 0 ? (
                notice.map((res, index) => (
                  <div
                    key={index}
                    className="item"
                    onClick={() => this.modifyNotice(res.board_id)}
                  >
                    <span className="notice-date">
                      {res.board_created.substring(0, 10)}
                    </span>
                    <span className="notice-title">{res.title}</span>
                  </div>
                ))
              ) : (
                <div>
                  <div className="notice-Letter">
                    <p>작성된 글이 없습니다.</p>
                  </div>
                </div>
              )}
              <ul className="notice-pagination">
                {this.state.nowPage > 1 ? (
                  <span onClick={this.Pagination}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-left"
                      className="svg-inline--fa fa-chevron-left fa-w-10"
                      id={
                        this.state.nowPage > 1
                          ? this.state.nowPage - 1
                          : this.state.nowPage
                      }
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        id={
                          this.state.nowPage > 1
                            ? this.state.nowPage - 1
                            : this.state.nowPage
                        }
                        fill="black"
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                      />
                    </svg>
                  </span>
                ) : (
                  <span>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-left"
                      className="svg-inline--fa fa-chevron-left fa-w-10"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="gary"
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                      />
                    </svg>
                  </span>
                )}

                {pageCount.length === 0 ? (
                  <div>1</div>
                ) : (
                  pageCount.map(res => (
                    <div
                      key={res}
                      className={this.state.nowPage == res ? "active" : ""}
                      onClick={this.Pagination}
                      id={res}
                    >
                      {res}
                    </div>
                  ))
                )}
                {this.state.nowPage < pageCount[pageCount.length - 1] ? (
                  <span onClick={this.Pagination} id={this.state.nowPage + 1}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-right"
                      className="svg-inline--fa fa-chevron-right fa-w-10 "
                      id={this.state.nowPage + 1}
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        id={this.state.nowPage + 1}
                        fill="black"
                        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                      />
                    </svg>
                  </span>
                ) : (
                  <span>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-right"
                      className="svg-inline--fa fa-chevron-right fa-w-10 "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="gary"
                        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                      />
                    </svg>
                  </span>
                )}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Notice;
